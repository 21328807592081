import PropTypes from 'prop-types'
import React from 'react'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <svg
      width="100"
      height="100"
      viewBox="0 0 279 279"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M278.861 139.137C278.861 215.98 216.572 278.267 139.729 278.267C62.8889 278.267 0.599609 215.98 0.599609 139.137C0.599609 62.2933 62.8889 -8.29299e-06 139.729 -8.29299e-06C216.572 -8.29299e-06 278.861 62.2933 278.861 139.137Z"
        fill="#559A33"
      />
      <path
        d="M266.164 139.137C266.164 208.971 209.551 265.559 139.73 265.559C69.9004 265.559 13.2964 208.971 13.2964 139.137C13.2964 69.304 69.9004 12.696 139.73 12.696C209.551 12.696 266.164 69.304 266.164 139.137Z"
        fill="#FEFCE7"
      />
      <path
        d="M260.4 139.137C260.4 205.781 206.382 259.801 139.729 259.801C73.0865 259.801 19.0598 205.781 19.0598 139.137C19.0598 72.4893 73.0865 18.4613 139.729 18.4613C206.382 18.4613 260.4 72.4893 260.4 139.137Z"
        fill="#A2E1F4"
      />
      <path
        d="M226.246 139.962C221.52 132.087 201.141 128.399 187.4 134.55C166.056 144.108 153.612 186.914 200.48 207.546C249.19 223.359 268.394 183.127 258.842 161.794C252.694 148.044 235.032 137.238 226.246 139.962Z"
        fill="#E31F28"
      />
      <path
        d="M225.151 142.861C220.031 141.053 206.209 134.463 209.823 122.095C210.833 125.235 216.719 133.289 220.247 134.639C218.54 130.539 213.671 128.681 217.121 119.625C217.477 120.865 221.04 129.276 225.853 133.697C223.937 130.749 222.901 124.044 225.4 121.124C225.976 122.799 227.535 127.113 229.564 131.276C233.839 129.517 237.88 127.339 239.44 126.468C239.343 130.313 234.1 134.631 230.713 135.547C237.245 135.457 245.517 131.556 246.607 130.867C243.149 139.919 238.271 138.056 234.273 139.989C237.803 141.331 247.545 139.239 250.404 137.568C244.865 149.192 230.165 144.916 225.151 142.861Z"
        fill="#39712E"
      />
      <path
        d="M227.421 135.439C227.421 135.439 226.159 122.087 237.784 114.787C239.553 117.395 240.581 118.345 240.581 118.345C240.581 118.345 228.808 126.017 229.356 136.177C228.632 138.065 227.421 135.439 227.421 135.439Z"
        fill="#39712E"
      />
      <path
        d="M245.073 152.946C253.609 158.137 268.534 188.577 230.933 200.918C260.993 177.558 224.338 144.969 245.073 152.946Z"
        fill="#F4688A"
      />
      <path
        d="M48.5561 109.602C52.7961 108.407 76.1095 102.496 79.4522 115.706C83.1362 130.259 83.5628 134.256 81.3855 137.61C86.3495 137.927 92.0535 164.034 87.3668 165.268C93.4201 167.463 96.1441 195.276 91.5028 198.263C96.9201 198.752 97.9055 239.952 89.0841 240.436C81.5241 245.031 59.0588 210.484 63.2468 207.027C57.7255 207.182 44.2535 182.698 48.0001 177.471C43.4535 179.079 33.3935 154.331 37.3041 151.256C33.6028 149.724 31.7041 146.179 26.5255 132.1C21.8055 119.311 44.3748 111.016 48.5561 109.602Z"
        fill="#F79613"
      />
      <path
        d="M49.3556 112.181C50.3063 112.684 51.2209 111.864 51.2209 111.864C51.2209 111.864 56.0529 85.3796 63.7343 81.561C58.5703 76.9636 50.9596 80.3503 50.9596 80.3503C49.4343 85.3223 48.1636 101.114 47.9809 107.76C44.0876 102.382 34.1089 90.069 30.0503 86.8303C30.0503 86.8303 21.8463 88.341 20.1836 95.0463C28.6836 93.8623 47.6396 112.968 47.6396 112.968C47.6396 112.968 48.8596 113.129 49.3556 112.181"
        fill="#4A792D"
      />
      <path
        d="M48.8792 110.643C48.6165 105.071 47.5645 82.0799 48.1645 79.3612C43.9312 77.3985 40.3085 79.0919 39.3445 79.8599C38.1139 79.7719 34.1779 80.4212 31.7832 84.4332C33.8219 86.3425 45.9525 105.897 48.8792 110.643Z"
        fill="#4A792D"
      />
      <path
        d="M49.3556 112.181L51.8463 108.774C51.8463 108.774 55.7836 107.325 59.3636 107.52C56.3236 108.336 53.205 110.933 53.205 110.933C56.7316 110.328 61.5996 113.385 61.5996 113.385C61.5996 113.385 54.8863 112.684 52.605 114.013C53.7703 114.993 52.169 121.266 52.169 121.266C52.169 121.266 47.293 116.994 47.7103 115.525C45.081 115.717 39.9343 120.105 39.9343 120.105C39.9343 120.105 42.225 114.822 45.473 113.325C45.473 113.325 41.441 112.949 38.4636 113.99C41.301 111.804 45.3783 110.782 45.3783 110.782L49.3556 112.181Z"
        fill="#4A792D"
      />
      <path
        d="M64.396 109.635C67.9653 109.665 71.232 110.203 73.5573 111.545C75.4467 112.638 76.6307 114.193 77.1627 116.281C80.636 130.037 81.0547 133.781 79.4013 136.322L77.1973 139.713L81.064 139.962C81.812 140.483 83.8053 143.691 85.4253 150.49C87.0893 157.45 86.8893 161.917 86.3933 163.085L79.46 164.919L86.568 167.509C87.0893 167.691 88.8813 169.742 90.276 176.51C92.2187 185.933 91.556 195.022 90.2253 196.278L84.4253 200.003L90.8693 200.582C92.8627 203.273 94.5787 220.829 91.8947 232.177C90.8867 236.407 89.648 237.854 88.9613 238.069L88.3507 238.102L87.8453 238.405C87.8213 238.426 87.7853 238.446 87.7507 238.451C87.1693 238.635 85.3307 238.15 82.092 235.217C77.5453 231.079 73.0333 224.831 69.7933 219.363C76.6147 208.338 86.2453 179.577 64.396 109.635Z"
        fill="#DB7F18"
      />
      <path
        d="M36.8784 118.884C36.8784 118.884 28.9091 123.209 30.3971 128.009C31.8797 132.813 35.4064 149.995 41.9117 150.143C41.1357 155.645 44.2624 171.404 51.5517 176.267C47.1344 167.415 47.9717 148.889 47.9717 148.889C41.4411 141.627 35.4944 131.387 35.3624 126.475C35.2317 121.559 36.8784 118.884 36.8784 118.884Z"
        fill="#FBC374"
      />
      <path
        d="M168.181 96.6667C169.957 94.6373 170.888 91.872 170.724 88.9907C170.514 85.4627 170.001 76.528 166.282 72.3053C166.378 72.188 166.508 72.0987 166.602 71.988C168.266 70.044 169.006 67.4933 168.617 64.98C167.804 59.636 166.385 53.032 163.138 49.656C163.634 48.784 164.026 47.556 163.897 45.832C163.546 41.22 161.57 35.88 157.07 34.6973C156.842 34.6427 156.624 34.636 156.389 34.6067C154.786 28.7453 152.366 23.672 147.445 23.568C147.194 23.568 146.941 23.608 146.696 23.6413C145.678 20.2027 143.936 18.0973 140.296 18.0973C136.656 18.0973 134.914 20.2027 133.905 23.652C133.617 23.608 133.346 23.568 133.042 23.568C128.22 23.672 125.814 28.7453 124.212 34.6067C123.986 34.6427 123.761 34.6427 123.533 34.6973C119.032 35.88 117.062 41.22 116.706 45.832C116.582 47.5387 116.958 48.748 117.454 49.6347C114.225 52.9987 112.796 59.6213 111.988 64.98C111.604 67.4933 112.333 70.044 113.998 71.988C114.094 72.0987 114.225 72.188 114.328 72.3053C110.601 76.528 110.089 85.4627 109.886 88.9907C109.722 91.872 110.645 94.6373 112.421 96.6667C110.853 98.212 109.878 100.353 109.878 102.723V116.377C109.878 118.897 111.004 121.139 112.733 122.689C111.004 124.253 109.878 126.489 109.878 129.007V142.663C109.878 147.357 113.694 151.168 118.386 151.168C120.266 151.168 121.982 150.536 123.393 149.507C124.936 151.064 127.077 152.04 129.437 152.04C131.606 152.04 133.573 151.197 135.072 149.863C136.508 151.213 138.301 152.04 140.296 152.04C142.298 152.04 144.102 151.213 145.538 149.863C147.037 151.197 148.997 152.04 151.162 152.04C153.524 152.04 155.666 151.064 157.217 149.507C158.617 150.536 160.344 151.168 162.222 151.168C166.909 151.168 170.733 147.357 170.733 142.663V129.007C170.733 126.489 169.608 124.253 167.866 122.689C169.608 121.139 170.733 118.897 170.733 116.377V102.723C170.733 100.353 169.748 98.212 168.181 96.6667Z"
        fill="#EDB11B"
      />
      <path
        d="M127.199 37.9102L127.164 38.0462L124.36 38.3635C121.077 39.3902 119.911 46.3315 120.729 47.7968L122.088 50.2208L120.163 52.2288C118.248 54.2315 116.741 58.7168 115.695 65.5475C115.487 66.9315 115.869 68.3302 116.741 69.4142C116.853 69.5115 116.949 69.6062 117.055 69.7142L119.424 72.2008L117.141 74.7862C114.276 78.0435 113.797 86.4475 113.64 89.2075C113.528 91.0782 114.119 92.8942 115.253 94.1888L117.585 96.8528L115.061 99.3382C114.136 100.243 113.64 101.446 113.64 102.722V116.377C113.64 117.71 114.207 118.962 115.253 119.894L118.368 122.693L115.243 125.496C114.207 126.422 113.64 127.667 113.64 129.007V142.662C113.64 145.286 115.764 147.415 118.387 147.415C119.632 147.415 120.589 146.903 121.173 146.485L123.785 144.562L126.067 146.87C126.965 147.785 128.165 148.287 129.437 148.287C130.892 148.287 131.947 147.618 132.573 147.062L135.141 144.77L137.641 147.121C138.467 147.895 139.355 148.287 140.296 148.287C141.247 148.287 142.143 147.895 142.961 147.121L145.471 144.77L148.031 147.062C148.665 147.618 149.709 148.287 151.163 148.287C152.436 148.287 153.639 147.785 154.544 146.87L154.883 146.521C150.789 136.377 122.427 123.098 125.667 96.3342C123.812 87.8648 122.427 76.6155 130.672 69.3275C126.136 71.2235 119.563 61.5328 127.827 50.7968C122.427 47.9808 123.785 40.9408 127.199 37.9102Z"
        fill="#C7931E"
      />
      <path
        d="M137.621 152.04C138.912 151.581 142.534 137.438 158.409 125.442C175.198 112.77 182.532 100.088 184.882 67.5451C198.022 110.108 201.776 183.974 163.592 216.517C125.413 249.06 137.621 208.386 137.621 208.386V152.04Z"
        fill="#4A792D"
      />
      <path
        d="M190.125 125.294C190.221 138.132 188.427 151.006 185.239 163.512C183.69 169.769 181.713 175.937 179.387 181.984C177.001 188.005 174.214 193.882 171.071 199.553C165.507 209.32 158.681 218.469 150.434 226.149C145.207 229.117 141.735 229.641 139.503 228.726C140.027 228.208 140.514 227.71 141.001 227.18C142.735 225.208 144.441 223.238 146.062 221.192C149.074 216.316 151.818 211.257 154.091 205.964C159.254 194.436 162.875 182.206 165.358 169.762C167.763 157.284 169.319 144.577 169.47 131.753C170.766 144.533 170.209 157.509 168.234 170.266C167.273 176.65 165.89 182.98 164.141 189.216C163.593 191.085 162.998 192.954 162.39 194.792C166.317 186.557 169.53 177.961 172.159 169.197C175.746 157.004 178.481 144.5 179.858 131.753C179.927 144.596 178.125 157.46 174.971 169.992C173.414 176.234 171.419 182.413 169.094 188.461C166.735 194.489 163.93 200.346 160.779 206.017C157.506 211.761 153.793 217.284 149.623 222.43C150.19 221.872 150.755 221.306 151.278 220.722C153.358 218.341 155.458 216.013 157.341 213.477C161.211 208.478 164.758 203.26 167.805 197.718C174.033 186.734 178.787 174.902 182.443 162.736C186.014 150.542 188.758 138.037 190.125 125.294Z"
        fill="#5A9C35"
      />
      <path
        d="M115.418 119.468C99.2981 98.3935 97.7741 75.1508 94.3688 61.5335C72.3821 114.488 91.4008 234.599 144.546 229.136C182.079 184.695 133.495 143.097 115.418 119.468Z"
        fill="#5A9C35"
      />
      <path
        d="M135.708 228.853C132.98 228.403 130.362 227.596 127.859 226.452C122.131 219.113 116.906 211.372 112.334 203.26C104.82 189.947 98.8804 175.648 95.3711 160.757C92.0351 148.087 90.6591 131.955 92.1031 115.436C93.0978 128.017 95.1084 146.103 99.6098 159.616C101.744 166.756 104.288 173.757 107.275 180.557C110.15 187.413 113.544 194.039 117.228 200.501C120.816 206.999 124.847 213.268 129.132 219.349C130.639 221.515 132.182 223.647 133.756 225.753C134.392 226.781 135.036 227.825 135.708 228.853Z"
        fill="#A3DA69"
      />
      <path
        d="M141.15 229.317C139.461 229.317 137.814 229.189 136.178 228.954C135.367 227.892 134.558 226.816 133.757 225.753C129.865 219.309 126.321 212.65 123.15 205.802C120.738 200.238 118.265 194.677 116.254 188.946C110.393 173.1 105.263 153.381 103.687 136.092C109.87 157.232 117.639 178.566 121.207 187.056C123.367 192.613 125.815 198.04 128.253 203.461L132.086 211.526C132.618 212.702 136.065 219.276 138.938 224.421V224.441C139.67 226.116 140.401 227.724 141.15 229.317Z"
        fill="#A3DA69"
      />
      <path
        d="M146.218 227.071C145.696 227.771 145.131 228.436 144.547 229.136C143.614 229.23 142.674 229.291 141.758 229.311C141.342 228.604 140.932 227.919 140.514 227.218C140.027 226.378 139.503 225.431 138.939 224.44V224.422C136.647 219.148 134.471 213.511 132.398 207.308C130.639 201.847 128.967 196.366 127.618 190.808C124.83 179.704 122.69 168.438 121.834 157.047C124.987 168.014 128.27 178.843 131.823 189.584C135.935 201.902 140.61 214.775 146.218 227.071Z"
        fill="#A3DA69"
      />
      <path
        d="M128.724 221.368C128.724 221.368 132.259 228.403 136.7 225.747C138.737 227.159 140.296 223.855 140.296 223.855L144.547 229.136C144.547 229.136 135.168 230.152 131.048 227.696C126.929 225.248 128.724 221.368 128.724 221.368Z"
        fill="#A3DA69"
      />
      <path
        d="M143.04 26.3914C143.04 26.3914 149.614 23.1594 153.132 37.91C156.649 37.91 160.421 41.9434 158.409 51.742C161.588 52.2074 166.072 63.7087 162.529 72.8647C162.998 67.5447 157.73 55.022 153.437 53.3794C154.882 47.5114 152.061 40.7034 149.144 40.4674C149.378 34.834 143.04 26.3914 143.04 26.3914Z"
        fill="#FCD97F"
      />
    </svg>

    <div className="content">
      <div className="inner">
        <h1>Alimentos del Futuro</h1>
        <p>Nos Especializamos para dar un Alimento completo a cada hogar.</p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Nosotros
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            Valores
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Servicios
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
